<template>
<div class="meo-login">
    <v-container px-5 pb-6 v-if="error" class="dark--text">
        <v-row>
            <v-col class="shrink">
                <h2 class="text-no-wrap my-6">Log In</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p v-text="error"></p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn block x-large color="white" @click="clearError()">Try Again</v-btn>
            </v-col>
        </v-row>
    </v-container>

    <v-container px-5 pb-6 v-else class="dark--text">
        <form @submit.prevent="submit">
            <v-row>
                <v-col class="shrink">
                    <h2 class="text-no-wrap my-6">Log In</h2>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-text-field ref="email" v-model="email" type="email" class="pt-0 mt-0" color="dark" :disabled="loading" label="Email Address" placeholder="Please enter your email..." required outlined :rules="[rules.required, rules.email]" />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12">
                    <v-text-field ref="password" v-model="password" class="pt-0 mt-0" color="dark" :disabled="loading" label="Password" placeholder="Please enter your password..." required outlined :rules="[rules.required]"
                      :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="showPassword = !showPassword" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large color="white" type="submit" :loading="loading"><span class="dark--text">Log In</span></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block :to="{name:'auth.signup'}" x-large color="dark" outlined :disabled="loading">Sign Up</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <v-btn :to="{name:'auth.forgot'}" small text color="dark" :disabled="loading">
                        <span class="text-capitalize font-weight-regular">Forgotten your password?</span>
                    </v-btn>
                </v-col>
            </v-row>
        </form>
    </v-container>
</div>
</template>

<script>
import _ from 'lodash';
import UserMixin from '@/mixins/user-mixin';

export default {
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Login | %s"
    },
    data() {
        return {
            password: '',
            showPassword: false,
            error: '',
            loading: false,
            rules: {
                required: value => !!value || 'This field is required',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail'
                },
            }
        }
    },
    methods: {
        clearError() {
            this.error = false;
            this.$meo.error = {};
        },
        submit() {
            //Check if there is an application
            var applicationContext = (this.application && this.application.authenticationStyle == 'application');

            this.loading = true;

            this.$fluro.auth.login({
                    username: this.email,
                    password: this.password,
                }, {
                    bypassInterceptor: true,
                    application: applicationContext, //Sign in to the application rather than Fluro
                })
                .then(res => {
                    var user = res.data;
                    console.log('Login success', user);
                    // this.$router.push({ name: 'profile.menu' });
                    this.$router.push({ name: 'modules' });
                    this.$meo.error = {};
                }, err => {
                    var message = this.$fluro.utils.errorMessage(err);

                    this.error = 'An error occured while trying to log you in. Please try again.';

                    if (message.indexOf('persona') > -1 || message.indexOf('password') > -1) {
                        this.error = 'Could not log you in. Please check that your email address and password are correct and try again.';
                    }

                    console.log('ERROR!', message, err);

                    this.loading = false;
                    this.password = '';
                });
        }
    },
    computed: {
        email: {
            set(email) {
                this.$store.commit('email', email);
            },
            get(email) {
                return this.$store.getters.email;
            }
        }
    }
}
</script>
